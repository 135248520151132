<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="采集时间：" label-width="100">
                <el-date-picker
                        v-model="searchForm.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy年MM月dd日"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标题：" label-width="100">
                <el-input
                        v-model="searchForm.title"
                        placeholder="标题"
                        clearable>
                </el-input>
            </el-form-item >
            <el-form-item label="采集类型：" label-width="100">
                <el-select v-model="searchForm.acquisitionType" clearable placeholder="选择采集类型">
                    <el-option label="网页" value="1"></el-option>
                    <el-option label="微信公众号" value="2"></el-option>
<!--                    <el-option label="抖音" value="3"></el-option>-->
                    <el-option label="微博" value="4"></el-option>
                    <el-option label="吴江日报" value="5"></el-option>
                    <el-option label="吴江新闻" value="6"></el-option>
                    <el-option label="微信文章" value="9"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getCollectionResults" type="success"  >搜索</el-button>
                <el-button icon="plus" @click="exportExcel" type="primary" v-if="hasAuth('sys:reptile:export')">导出</el-button>
            </el-form-item>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" align="center" width="55"></el-table-column>
            <el-table-column
                    label="标题"
                    align="center"
                    prop="title"
                    show-overflow-tooltip
                    width="350">
            </el-table-column>
            <el-table-column
                    label="网址"
                    align="center"
                    prop="sourceUrl"
                    width="350">
            </el-table-column>
            <el-table-column
                    prop="isAcquisitionSucceeded"
                    align="center"
                    label="采集结果"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isAcquisitionSucceeded === 1" type="success">成功</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isAcquisitionSucceeded ===2"  type="info">失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="acquisitionType"
                    align="center"
                    label="采集类型"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.acquisitionType === 1" type="success">网页</el-tag>
                    <el-tag size="small" v-else-if="scope.row.acquisitionType ===2"  type="info">微信公众号</el-tag>
                    <el-tag size="small" v-if="scope.row.acquisitionType === 3" type="success">抖音</el-tag>
                    <el-tag size="small" v-else-if="scope.row.acquisitionType ===4"  type="info">微博</el-tag>
                    <el-tag size="small" v-else-if="scope.row.acquisitionType ===5"  type="info">吴江日报</el-tag>
                    <el-tag size="small" v-else-if="scope.row.acquisitionType ===6"  type="info">吴江新闻</el-tag>
                    <el-tag size="small" v-else-if="scope.row.acquisitionType ===9"  type="info">微信文章</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    label="发布时间"
                    align="center"
                    prop="releaseTime"
                    width="200"
            ></el-table-column>
            <el-table-column
                    label="作者"
                    align="center"
                    prop="author"
                    width="120"
            ></el-table-column>
            <el-table-column
                    label="发布者"
                    align="center"
                    prop="publisher"
                    width="120"
            ></el-table-column>
            <el-table-column
                    label="浏览量"
                    align="center"
                    prop="views"
                    width="120"
            ></el-table-column>
            <el-table-column
                    label="采集时间"
                    align="center"
                    prop="createTime"
                    width="200"
            ></el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="300"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="collectionResultsInfo(scope.row.reptileId,true)" v-if="hasAuth('sys:reptile:seereptile')">查看</el-button>
                    <el-button type="primary" plain @click="collectionResultsInfo(scope.row.reptileId,false)" v-if="hasAuth('sys:reptile:updatereptile')">编辑</el-button>
                    <template v-if="hasAuth('sys:reptile:deletereptile')">
                        <el-popconfirm title="确定删除该采集结果吗？" @confirm="delHandle(scope.row.reptileId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total">
        </el-pagination>
<!--        <AddOrUpdateAcquisitiontask ref="addOrUpdateAcquisitiontask" v-if="addOrUpdateAcquisitiontaskVisible" @refreshDataList="getAcquisitionTaskList"></AddOrUpdateAcquisitiontask>-->
        <CollectionResultsInfo ref="collectionResults" v-if="collectionResultsVisible" @refreshDataList="getCollectionResults" ></CollectionResultsInfo>
    </div>
</template>

<script>
    import CollectionResultsInfo from "./CollectionResultsInfo";
    import ExportJsonExcel from "js-export-excel";
    export default {
        name: "CollectionResultsList",
        components:{
            CollectionResultsInfo
        },
        data(){
            return{
                tableData:[],
                multipleSelection:[],
                total: 0,
                size: 10,
                current: 1,
                acquisitionTaskId:0,
                searchForm:{},
                collectionResultsVisible:false
            }
        },
        created() {
          this.getCollectionResults()
        },
        methods:{
            handleClose() {
                this.dialogTableVisible=false
                this.tableData=[]
                this.multipleSelection=[]
                this.total=0
                this.size= 10
                this.current= 1
                this.acquisitionTaskId=0
                this.searchForm={}
                this.$emit('refreshDataList')
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },
            collectionResultsInfo(id,isSee){
                this.collectionResultsVisible=true
                this.$nextTick(()=>{
                    this.$refs.collectionResults.init(id,isSee)
                })
            },
            handleSizeChange(val) {
                this.size = val
                this.getCollectionResults()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getCollectionResults()
            },
            getCollectionResults(){
                var startTime='';
                var endTime='';
                if(this.searchForm.createTime!=undefined){
                    startTime=this.searchForm.createTime[0];
                    endTime=this.searchForm.createTime[1];
                }
                this.searchForm.startTime=startTime
                this.searchForm.endTime=endTime;
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.$axios.post("/admin/reptile/getReptilePage", this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.current
                    this.total = res.data.total
                })
            },
            exportExcel() {
                const loading = this.$loading({
                    lock: true,
                    text: '正在导出中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var startTime='';
                var endTime='';
                var searchForm={
                    "pageNo":1,
                    "pageSize":10000
                }
                if(this.searchForm.createTime!=undefined){
                    searchForm.startTime=this.searchForm.createTime[0];
                    searchForm.endTime=this.searchForm.createTime[1];
                }
                if (this.multipleSelection){
                    var idList=[];
                    this.multipleSelection.forEach((item,index) => {
                        idList.push(item.reptileId)
                    })
                    searchForm.idList=idList;
                }
                var ids=[];
                this.$axios.post("/admin/reptile/exportExcel", searchForm).then(res => {
                    const dataList = res.data
                    let option = {};  //   option代表的就是excel文件
                    var  tableHeader=['标题','来源网址','图片地址','发布时间','作者', '发布者','浏览量', '文章来源','采集时间','视频地址','附件地址','内容']
                    var tableWith=['40','30','30','10','6','6','6', '7','7','30','30','50']
                    let dataTable = [];   //   dataTable代表excel文件中的数据内容
                    if (dataList) {
                        for (let i in dataList) {
                            ids.push(dataList[i].reptileId)
                            let obj = {
                                标题: dataList[i].title,
                                来源网址: dataList[i].sourceUrl,
                                图片地址: dataList[i].imgUrl,
                                发布时间:dataList[i].releaseTime,
                                作者:dataList[i].author,
                                发布者:dataList[i].publisher,
                                浏览量:dataList[i].views,
                                文章来源:dataList[i].articleSource,
                                采集时间:dataList[i].createTime,
                                视频地址:dataList[i].videoUrl,
                                附件地址:dataList[i].enclosureUrl,
                                内容:dataList[i].publishingContent

                            };
                            dataTable.push(obj);   //   设置excel每列获取的数据源
                        }
                    }

                    option.fileName ="采集结果";  //excel文件名
                    //excel文件数据
                    option.datas = [
                        {
                            //   excel文件的数据源
                            sheetData: dataTable,
                            //   excel文件sheet的表名
                            sheetName: "采集结果",
                            //   excel文件表头名
                            sheetHeader: tableHeader,
                            //   excel文件列名
                            sheetFilter: tableHeader,
                            columnWidths: tableWith,
                        },
                    ];
                    //   创建ExportJsonExcel实例对象
                    let toExcel = new ExportJsonExcel(option,'sss');
                    //   调用保存方法
                    toExcel.saveExcel();
                    this.updateexport(ids)
                    loading.close()
                })
            },

            //删除资讯
            delHandle(id) {
                this.$axios.post("/admin/reptile/deleteReptileInfo ", {reptileId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.getCollectionResults()
                        }
                    });
                })
            },

            updateexport(ids){
                this.$axios.post("/admin/reptile/updateExport", {ids:ids}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
